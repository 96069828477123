import { createWebHistory, createRouter } from 'vue-router'

import RoomList from './components/RoomList.vue'
import UsersSearch from './components/UsersSearch.vue'
import Home from './components/Home.vue'
import PublicChat from './components/PublicChat.vue'
import RoomLobby from './components/RoomLobby.vue'
import UserInfo from './components/UserInfo.vue'
import Login from './components/Login.vue'
import FriendsList from './components/FriendsList.vue'
import PrivateChat from './components/PrivateChat.vue'
import Settings from './components/Settings.vue'


const routes = [
  { path: '/', name: 'home', component: Home},
  { path: '/login', name: 'login', component: Login},
  { path: '/chat', name: 'chat', component: PublicChat, props: {'players': []}, meta: { requiresAuth: true }},
  { path: '/rooms', name: 'rooms', component: RoomList, props: {'rooms': []}, meta: { requiresAuth: true } },
  { path: '/users', name: 'users', component: UsersSearch, props: {'userSearchResult': []}, meta: { requiresAuth: true } },
  { path: '/friends', name: 'friends', component: FriendsList, props: {'friends': []}, meta: { requiresAuth: true } },
  { path: '/private/:id', name: 'private', component: PrivateChat, meta: { requiresAuth: true } },
  { path: '/game', name: 'game', component: RoomLobby, props: {'players': [], timer: -1}, meta: { requiresAuth: true } },
  { path: '/user/:id', name: 'user', component: UserInfo, props: {'userinfo': null}, meta: { requiresAuth: true }},
  { path: '/settings', name: 'settings', component: Settings}
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
/*
router.beforeEach((to, from) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/login',
      // save the location we were at to come back later
    }
  }
})
*/

export default router