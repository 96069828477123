<template>
    <form @submit.prevent="sendPrivateMessage" class="chat-send-message-form">
        <SendFeatures ref="sendfeature_ref"></SendFeatures>
        <label>
            Message
            <input ref="sendmessage_input_ref" class="chat-send-message-ibox" type="text" v-model="message" />
        </label>
        <button @click="submit">Send</button>
    </form>
</template>

<script>
    import SendFeatures from './SendFeatures.vue'
    import {ref} from 'vue'
    const sendmessage_input_ref = ref(null)

    export default {
        data(){
            return {
                message: '',
            }
        },
        props: ['friend_id'],
        methods: {
            sendPrivateMessage(){
                this.$parent.$emit('send-message-event', JSON.stringify({"ty":"pmc","m":{"fp":this.friend_id,"tx":this.$refs.sendfeature_ref.applyFeature(this.message)}}));
                this.message = ''
            },
            addText(text){
                this.message += text;
                this.$refs.sendmessage_input_ref.focus()
            }
        },
        components: {
            SendFeatures
        }
    }
</script>

<style>
.chat-send-message-form {
    display: flex;
    margin-top: 10px;
}
.chat-send-message-ibox {
    width: 300px;
}
</style>