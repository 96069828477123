
/*
  // Create an Object to encode/decode morse code with
    m = Object.create(MorseCode);

  // Encoding text into morse code
    m.encode('test message') or m.morse('test message')

  // Decoding the morse code into plain text
    m.decode('.- -... -.-.') or m.morse('.- -... -.-.', true)

  // Without creating an object, just call it straight from the Object
    MorseCode.encode('test message');
    MorseCode.decode('.- -... -.-.');

  // Run a test to make sure the functions are working correctly
    MorseCode.test();
*/

class MorseCode {

  static encode (data) {
    return MorseCode.morse.call(this, data);
  }

  static decode (data){
    return MorseCode.morse.call(this, data, true);
  }
  static encodeCyr (data) {
    return MorseCode.morse.call(this, data, false, true);
  }
  static decodeCyr (data){
    return MorseCode.morse.call(this, data, true, true);
  }

  static morse (data, decode=false, cyr=false) {
    this.map = {
        a: '.-',      b: '-...',    c: '-.-.',    d: '-..',
        e: '.',       f: '..-.',    g: '--.',     h: '....',
        i: '..',      j: '.---',    k: '-.-',     l: '.-..',
        m: '--',      n: '-.',      o: '---',     p: '.--.',
        q: '--.-',    r: '.-.',     s: '...',     t: '-',
        u: '..-',     v: '...-',    w: '.--',     x: '-..-',
        y: '-.--',    z: '--..',    1: '.----',   2: '..---',
        3: '...--',   4: '....-',   5: '.....',   6: '-....',
        7: '--...',   8: '---..',   9: '----.',   0: '-----',

        '.': '.-.-.-',    ',': '--..--',    '?': '..--..',
        "'": '.----.',    '/': '-..-.',     '(': '-.--.',
        ')': '-.--.-',    '&': '.-...',     ':': '---...',
        ';': '-.-.-.',    '=': '-...-',     '+': '.-.-.',
        '-': '-....-',    '_': '..--.-',    '"': '.-..-.',
        '$': '...-..-',   '!': '-.-.--',    '@': '.--.-.',
        ' ': '/',

        'я':'.-.-','ю':'..--','ь':'-..-','ш':'----','ё':'#',
        'ч':'---.','ъ':'--.--','э':'..-..','ї':'.---.'
    }

    this.cyrMap = {
      'а': '.-',      'б': '-...',    'в': '.--',    'г': '--.',   'д': '-..',
      'е': '.',       'ж': '...-',    'з': '--..',   'и': '..',    'й': '.---',
      'к': '-.-',     'л': '.-..',    'м': '--',     'н': '-.',    'о': '---',
      'п': '.--.',    'р': '.-.',     'с': '...',    'т': '-',     'у': '..-',
      'ф': '..-.',    'х': '....',    'ц': '-.-.',   'ч': '---.',  'ш': '----',
      'щ': '--.-',    'ъ': '--.--',   'ы': '-.--',   'ь': '-..-',  'э': '..-..',
      'ю': '..--',    'я': '.-.-',    'ї': '.---.',  'є': '..-..', 'і': '..',
      'ґ': '--.',
      '0': '-----',   '1': '.----',   '2': '..---',  '3': '...--', '4': '....-',
      '5': '.....',   '6': '-....',   '7': '--...',  '8': '---..', '9': '----.',
      '.': '.-.-.-',  ',': '--..--',  '?': '..--..', "'": '.----.', '!': '-.-.--',
      '/': '-..-.',   '(': '-.--.',   ')': '-.--.-', '&': '.-...',  ':': '---...',
      ';': '-.-.-.',  '=': '-...-',   '+': '.-.-.',  '-': '-....-', '_': '..--.-',
      '"': '.-..-.',  '$': '...-..-', '@': '.--.-.', '¿': '..-.-',  '¡': '--...-',
      ' ': '/',
  };

    let curMap = cyr === true ? this.cyrMap : this.map
    if(decode) {
      curMap = (
        () => {
          var tmp = {};
          var k;
          for(k in curMap) {
            if(!Object.prototype.hasOwnProperty.call(curMap, k)) continue;
            tmp[curMap[k]] = k;
          }
          return tmp;
        }
      )();

      return data.split(' ').filter( (v) => {
        return Object.prototype.hasOwnProperty.call(curMap, v.toLowerCase());
      }).map( (v) => {
        return curMap[v.toLowerCase()]
      }).join('');

    } else {
      console.log('encoding morse')
      return data.split('').filter( (v) => {
        return Object.prototype.hasOwnProperty.call(curMap, v.toLowerCase());
      }).map( (v) => {
        return curMap[v.toLowerCase()]
      }).join(' ').replace(/,\/,/g, '/');
    }
  }
}

export default MorseCode