<template>
    <div v-if="!createRoomToggle" class="roomlist">
        <label for="free-rooms">Only free rooms</label>
        <input id="free-rooms" type="checkbox" v-model="onlyFreeRooms">
        <button @click="createRoom" class="createroom-button">Create Room</button>
        <ul class="room-list">
            <template v-for="room in rooms" :key="room.id">
                <li class="room-list-el" v-if="onlyFreeRooms ? room.status < 2 : true">
                    <RoomButton :room="room"></RoomButton>
                </li>
            </template>
        </ul>
    </div>
    <CreateRoom v-if="createRoomToggle"></CreateRoom>
</template>

<script>
    import RoomButton from './RoomButton.vue'
    import CreateRoom from './CreateRoom.vue'

    export default {
        data(){
            return {
                createRoomToggle: false,
                onlyFreeRooms: false,
            }
        },
        props: ['rooms'],
        components: {
            RoomButton,
            CreateRoom,
        },
        methods: {
            createRoom(){
                this.createRoomToggle = !this.createRoomToggle
            }
        },
    }
</script>

<style>
.room-list-started{
    opacity: 30%;
    font-style: italic;
}
.room-list{
    color: aqua;
    display: flex;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 20px auto;
    padding: 0;
}
.room-list-el {
    flex: 1 1 100%;
    margin: 0 20px;
}
</style>