<template>
    <div class="dropdown-wrapper" ref="dropDown">
        <div class="dropdown-selected-option" @click="isDropdownVisible = !isDropdownVisible">
            {{ mappedSelectedOption }}
        </div>
        <Transition name="fade">
            <div class="options-wrapper" v-if="isDropdownVisible" ref="dropDownOptions">
                <div class="option" v-for="(option, index) in options" :key=index @click="toggleOptionSelect(option)">
                    {{ option.name }}
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'

const dropDown = ref(null);
const dropDownOptions = ref(null);

const props = defineProps({
    options: {
        type: Array,
        required: true
    },
        modelValue: {
        default: null
    }
})

const emit = defineEmits(['update:modelValue']);

const selectedOption = ref(null);

const toggleOptionSelect = (option) => {
    selectedOption.value = option;
    emit('update:modelValue', option);
    isDropdownVisible.value = false;
}

const mappedSelectedOption = computed(() => {
    return selectedOption.value?.name || 'Please, select option'
})

const isDropdownVisible = ref(false);

const closeDropdown = (element) => {
    if(!dropDown.value.contains(element.target)){
        isDropdownVisible.value = false;
    }
}
onMounted(() => {
    window.addEventListener('click', closeDropdown)
})

onBeforeUnmount(() => {
    window.removeEventListener('click', closeDropdown)
})

watch(isDropdownVisible, (newIsDropdownVisible) => {
  if(newIsDropdownVisible == true){
    nextTick(() => {
        const rect = dropDownOptions.value.getBoundingClientRect();
        const windowHeight = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
        if(rect.bottom > windowHeight){
            dropDownOptions.value.style.bottom = 46+'px';
        }
    });

    // if bottom > windowHeight - dropdown виходить вниз, за межі екрана
  }
})
</script>

<style>
.dropdown-wrapper {
    display: inline-block;
    padding: 6px;
    cursor: pointer;
    position: relative;
}

.dropdown-selected-option {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    padding: 5px 30px 5px 10px;
    margin-bottom: 4px;

    background-color: var(--nav-button-bg);
    box-shadow: rgba(165, 207, 219, 0.7) 1px 1px 20px 0px inset;
    border-radius: 5px;
    text-decoration: none;
    color: var(--nav-button-fg);
    transition: box-shadow 0.2s ease-out;
    position: relative;
}

.dropdown-selected-option::after {
    content: '';
    border: solid rgb(0, 0, 0);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 7px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    bottom: 13px;
}
.option {
    padding: 3px 8px;
    background-color: var(--nav-button-bg);
    box-shadow: rgba(165, 207, 219, 0.7) 1px 1px 20px 0px inset;
    text-decoration: none;
    color: var(--nav-button-fg);
    transition: box-shadow 0.2s ease-out;
}
.options-wrapper{
    border-radius: 5px;
    position: absolute;
    overflow: hidden;
}
.dropdown-selected-option:hover {
    box-shadow: rgba(8, 125, 221, 0.7) 1px 1px 30px 0px inset;
}
.dropdown-selected-option:focus {
    box-shadow: rgba(14, 58, 201, 0.7) 1px 1px 30px 0px inset;
}

.option:hover {
    box-shadow: rgba(6, 57, 151, 0.7) 1px 1px 30px 0px inset;
}
.option:focus {
    box-shadow: rgba(14, 58, 201, 0.7) 1px 1px 30px 0px inset;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>