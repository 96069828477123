<template>
        <button @click="enterRoom" :class="applyRoomClass">
            <div class="room-button-content">
                <div class="room-button-properties">
                    {{ room.level }}
                    <img width="24px" :src=getRankingIcon(room.level)>
                </div>
                <div class="room-button-title">
                    {{ room.title }} 
                </div>
                <div class="room-button-players">
                    <span>
                        {{ room.min_players }}/{{ room.max_players }}
                    </span>
                    <span>
                        {{ room.players }}
                    </span>
                </div>
            </div>
            <button class="room-button-info">i</button>
            <span class="room-button-roles">
                <img v-if="room.roles.includes(2)" width="32px" height="32px" src="/doctor.png">
                <img v-if="room.roles.includes(5)" width="32px" height="32px" src="/lover.png">
                <img v-if="room.roles.includes(7)" width="32px" height="32px" src="/journalist.png">
                <img v-if="room.roles.includes(11)" width="32px" height="32px" src="/informer.png">
                <img v-if="room.roles.includes(10)" width="32px" height="32px" src="">
                <img v-if="room.roles.includes(9)" width="32px" height="32px" src="/barman.png">
                <img v-if="room.roles.includes(8)" width="32px" height="32px" src="">
                <img v-if="room.roles.includes(6)" width="32px" height="32px" src="/terrorist.png">
            </span>
        </button>
</template>

<script>
    export default {
        props: ['room'],
        methods: {
            enterRoom(){
                this.$parent.$emit('send-message-event', JSON.stringify({"ty":"re","ro":this.room.id}))
                console.log(this.room.id)
            },
            getRankingIcon(ranking){
                switch(ranking){
                    case 1:
                        return '/rank1.svg'
                    case 3:
                        return '/rank2.svg'
                    case 5:
                        return '/rank3.svg'
                    case 7:
                        return '/rank4.svg'
                    default:
                        return ranking
                }
            }
        },
        computed: {
            applyRoomClass(){
                let m_class = 'room-button ';
                switch(this.room.status){
                    case 0:
                        break;
                    case 1:
                        m_class += 'room-button-timer'
                        break;
                    case 2:
                        m_class += 'room-button-started '
                        break;
                }
                switch(this.room.room_status){
                    case 0:
                        m_class += 'room-button-playerin'
                        break;
                    case 1:
                        m_class += 'room-button-died'
                        break;
                }
                return m_class
            }
        }
    }
</script>

<style>
.room-button {
    height: 95px;
    width: 100%;
    margin: 3px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}
.room-button-timer {
    background-color: orange
}
.room-button-playerin {
    background-color: rgb(8, 230, 0)
}
.room-button-died {
    background-color: rgb(255, 38, 0)
}
.room-button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.room-button-players {
    display: flex;
    flex-direction: column;
}
.room-button-info {
    position: absolute;
    right: -20px;
    top: -20px;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    background-color: #b9fff8;
    padding: 0;
}
.room-button-roles {
    display: flex;
}
</style>