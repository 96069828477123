import {defineStore, storeToRefs} from 'pinia'
import { useUserStore } from './UserStore'

export const usePlayersRoomStore = defineStore('playersRoomStore', {
    state: () => {
        return { 
            players: []
        }
    },
    getters: {
        role(state){
            const userStore = useUserStore()
            const { user } = storeToRefs(userStore)

            const me = state.players.find(e => {
                return e.id === user.value.id
            }) // Optional chaining to handle cases where no player is found
            if (me.data == null)
                return -1
            return me.data.role
        },
        whoOnMe(state){
            const userStore = useUserStore()
            const { user } = storeToRefs(userStore)

            const me = state.players.find(e => {
                return e.id === user.value.id
            }) 
            // Optional chaining to handle cases where no player is found
            if (me.data == null)
                return -1
            return me.data.abr
        }
    }
})