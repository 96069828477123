<template>
    <div>
        <label for="themes">Theme:</label>
        <Dropdown :options=this.options v-model=this.selectedTheme></Dropdown>
    </div>
</template>

<script>
import Dropdown from './Dropdown.vue';

export default {
    data() {
        return {
            theme: 0,
            options: [
                {name: 'Frutiger Aero', value: 0},
                {name: 'Club', value: 1},
            ],
            selectedTheme: null,
        }
    },
    watch: {
        selectedTheme: {
            handler (val, oldVal) {
                this.$parent.$emit('set-theme-event', val.value)
                this.theme = val.value;
            }
        }
    },
    components: {
        Dropdown
    }
}
</script>