<template>
    <div>
        <form @submit.prevent="searchUser">
        <label>
            Nickname
            <input type="text" v-model="nickname" />
        </label>
        <button @click="submit">Find</button>
        </form>
        <div class="user-grid-container">
            <div :class="user.s == '1' ? 'usersearch-girl' : 'usersearch-boy'" class="user-view" v-for="user in userSearchResult" :key="user.id">

                <div class="user-view-right">
                    <span v-bind:title="user.o">{{ user.u }}</span>
                </div>
                <div class="user-view-left">
                    <RouterLink v-bind:to='"/user/"+user.o'>
                        <img class="user-pfp" width="92px" height="92px" v-bind:src="'http://37.143.8.68/mafia/profile_photo/'+user.o+'.jpg'">
                    </RouterLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
              nickname: ""
            }
        },
        props: ['userSearchResult'],
        methods: {
            searchUser(){
                this.$emit('send-message-event', JSON.stringify({"ty":"su","st":this.nickname}));
            }
        }
    }
</script>

<style>
.user-grid-container{
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    margin: 20px auto auto auto;
    justify-content: center;
}

.user-view {
    display: flex;
    flex-direction: column;
    margin: 15px;
    flex: 0 1 300px;
    padding: 15px 5px;
    border-radius: 7px;
    max-width: 130px;
    align-items: center;
}

.user-view-left {
    margin: 0 12px;
}

.user-pfp {
    object-fit: cover;
}
.usersearch-girl {
    background-color: #ff6cdaaf;
    box-shadow: 0 0 8px #db7cbc;
}
.usersearch-boy {
    background-color: #16c1e8af;
    box-shadow: 0 0 8px #e4e5f8;
}
.user-view-right {
    margin-bottom: 8px;
}
</style>
