<template>
    <nav class="nav-menu">
        <ul class="nav-menu-list">
            <li v-if="this.$parent.logined && this.userStore.user!=null">
                <RouterLink class="nav-menu-button" to="/" @click="toProfile">{{ this.userStore.user.name }}</RouterLink>
            </li>
            <li v-if=!this.$parent.logined>
                <RouterLink class="nav-menu-button" to="/login">Login</RouterLink>
            </li>
            <li v-if=this.$parent.logined>
                <RouterLink class="nav-menu-button" to="/chat" @click="toChat">Chat</RouterLink>
            </li>
            <li v-if=this.$parent.logined>
                <RouterLink class="nav-menu-button" to="/rooms" @click="getRooms">List rooms</RouterLink>
            </li>
            <li v-if=this.$parent.logined>
                <RouterLink class="nav-menu-button" to="/users" @click="toProfile">Users</RouterLink>
            </li>
            <li v-if=this.$parent.logined>
                <RouterLink class="nav-menu-button" to="/friends" @click="toFriends">Friends</RouterLink>
            </li>
            <li>
                <RouterLink class="nav-menu-button" to="/settings">Settings</RouterLink>
            </li>
        </ul>
    </nav>
</template>

<style>
.nav-menu-list li {
    display: inline-block;
    padding: 4px;
}
</style>

<script>
    import { useUserStore } from '@/stores/UserStore';
    export default {
        props: ['text', 'iters'],
        setup(){
            const userStore = useUserStore()
            return {userStore}
        },
        methods: {
            getRooms(){
                this.$parent.sendMessage(JSON.stringify({"ty":"acrl", "uo":this.userStore.user.id, "t":this.userStore.user.token}));
            },
            toProfile(){
                this.$parent.sendMessage(JSON.stringify({"t":this.userStore.user.token,"ty":"acd","uo":this.userStore.user.id}));
            },
            toChat(){
                this.$parent.sendMessage(JSON.stringify({"t":this.userStore.user.token,"ty":"acc","uo":this.userStore.user.id}));
            },
            toFriends(){
                this.$parent.sendMessage(JSON.stringify({"t":this.userStore.user.token,"ty":"acfl","uo":this.userStore.user.id}));
            }
        }
    }
</script>

<style>
.nav-menu {
    padding: 10px 0;
    flex: 1;
}
.nav-menu-list {
    padding: 0 15px;
}
.nav-menu-button {
    background-color: var(--nav-button-bg);
    box-shadow: rgba(165, 207, 219, 0.7) 1px 1px 20px 0px inset;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    color: var(--nav-button-fg);
    transition: box-shadow 0.2s ease-out;
}

.nav-menu-button:hover {
    box-shadow: rgba(8, 125, 221, 0.7) 1px 1px 30px 0px inset;
}
.nav-menu-button:focus {
    box-shadow: rgba(14, 58, 201, 0.7) 1px 1px 30px 0px inset;
}
.income-messages-count {
    color: rgb(223, 22, 22);
    font-weight: bold;
}
</style>