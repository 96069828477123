<template>
    <h2>{{friends.length}} friends / {{friends.filter(item => item.online === 1).length}} online</h2>
    <div class="friends-container">
        <div :class="friend.sex == '1' ? 'friendlist-girl' : 'friendlist-boy'" class="friend-view" v-for="friend in friends" :key="friend.friend_id">
            <div class="friend-view-left">
                <span class="friend-username" v-bind:title="friend.friend_id">{{ friend.username }}</span>
                <RouterLink class="friend-link" v-bind:to='"/user/"+friend.user_id'>
                    <img class="friend-pfp" width="92px" height="92px" @click="pfpClicked(friend)" v-bind:src="'http://37.143.8.68/mafia/profile_photo/'+friend.user_id+'.jpg'">
                    <img v-if="friend.online" class="friend-online" width="24px" height="24px" src="/online.svg">
                </RouterLink>
            </div>
            <div class="friend-room-info">
                
            </div>
            <div class="friend-view-right">
                <div>
                    <div>
                        <button @click="openChat(friend)">Chat</button>
                        <span class="friend-new-messages" v-if="friend.new_messages>0">+{{ friend.new_messages }}</span>
                
                    </div>
                   
                    <button v-if="friend.room!=null" class="friend-room-button" @click="enterRoom(friend)">Room</button>
                   
                </div>
                <div v-if="friend.rr!=null">
                    {{ friend.rr.o }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from './../router';

    import { useUserStore } from '@/stores/UserStore';
    import { useCurrentFriendChatStore } from '@/stores/CurrentFriendChat';
    
    export default {
        props: ['friends'],
        setup(){
            const userStore = useUserStore()
            const currentFriendChatStore = useCurrentFriendChatStore()
            return {userStore, currentFriendChatStore}
        },
        methods: {
            openChat(fr){
                this.$emit('send-message-event', JSON.stringify({"ty":"acpc", "t":this.userStore.user.token, "uo":this.userStore.user.id, "fp":fr.friend_id}));
                router.push({ name: 'private', params: { id: fr.friend_id } })
                this.currentFriendChatStore.friend = fr
            },
            pfpClicked(fr){
                this.currentFriendChatStore.friend = fr
            },
            enterRoom(fr){

            }
        }
    }
</script>

<style>
.friendlist-container{
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    margin: 20px auto auto auto;
}

.friend-view {
    display: flex;
    margin: 15px;
    padding: 15px 5px;
    border-radius: 7px;
    max-width: 500px;
}

.friend-view-left {
    margin: 0 12px;
    display: flex;
    flex-direction: column;
}
.friend-view-right {
    display: flex;
    flex-direction: column;
    margin-left: 2px;
}
.friend-pfp {
    object-fit: cover;
}

.friend-link {
    position: relative;
    width: 92px;
}
.friend-online {
    position: absolute;
    bottom: -6px;
    right: -6px;

}
.friendlist-girl {
    background-color: #ff6cdaaf;
    box-shadow: 0 0 8px #db7cbc;
}
.friendlist-boy {
    background-color: #16c1e8af;
    box-shadow: 0 0 8px #e4e5f8;
}
.friend-username {
    margin-bottom: 8px;
}
.friend-room-info {
    background-color: green;
}
.online-text {
    color: #69ff69;
    font-weight: bold;
    text-shadow: #b9d98a 0px 0 10px;
}
.friend-new-messages {
    font-weight: bold;
    margin-left: 5px;
}
</style>
