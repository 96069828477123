<template>
    <form @submit.prevent="sendRoomMessage" class="room-send-message-form">
        <SendFeatures ref="sendfeature_ref"></SendFeatures>
        <label>
            Message
            <input ref="sendmessage_input_ref" class="room-send-message-ibox" type="text" v-model="message" />
        </label>
        <button @click="submit">Send</button>
    </form>
</template>

<script>
    import {ref} from 'vue'
    import { useRoomStore } from '@/stores/RoomStore';
    import SendFeatures from './SendFeatures.vue'
    const sendmessage_input_ref = ref(null)

    export default {
        data(){
            return {
                message: '',
            }
        },
        setup(){
            const roomStore = useRoomStore()
            return {roomStore}
        },
        methods: {
            sendRoomMessage(){
                this.$parent.$emit('send-message-event', JSON.stringify({"ty":"rmc","ro":this.roomStore.room.id,"m":{"tx":this.$refs.sendfeature_ref.applyFeature(this.message)}}));
                this.message = ''
            },
            addText(text){
                this.message += text;
                this.$refs.sendmessage_input_ref.focus()
            },
            
        },
        components: {
            SendFeatures
        }
    }
</script>

<style>
.room-send-message-form {
    display: flex;
    margin-top: 10px;
    align-items: center;
}
.room-send-message-ibox {
    width: 300px;
}
</style>