<template>
    <div class="message-features">
        <a>
            <span class="message-features-button-show" v-if="featureTextDisplay==false">i</span>
            <span class="message-features-button-hide" v-if="featureTextDisplay==true" @click="hideFeature">h</span>
        </a>
        <div class="message-features-content">
            <button @click="featuresDecodeBase64">base64</button>
            <button @click="featuresDecodeMorse">morse</button>
        </div>
    </div>
    <RouterLink v-if="message.userid != null" v-bind:to='"/user/"+message.userid'>
        <img @click="showUserInfo" class="message-user-pfp" width="40px" height="40px" v-bind:src="'http://37.143.8.68/mafia/profile_photo/'+message.userid+'.jpg'">
    </RouterLink>
    
    <p class="message"><span @click="addText(message.username)" class="message-username">{{this.$parent.computeUsername(message.userid)}}</span> {{ this.displayText }}</p>
    <span class="message-time">{{transcribTimestamp(message.time)}}</span>
</template>
<script>
    import MorseCode from '@/morsecode'
    import { Base64 } from 'js-base64';
    export default {
        props: ['message'],
        data(){
            return {
                featureText: "",
                featureTextDisplay: false,
                displayText: this.message.text,
            }
        },
        methods: {
            addText(text){
                this.$parent.$emit('add-text-event', '[' + text + '] ');
            },
            featuresDecodeBase64(){
                try {
                    this.featureText = Base64.decode(this.message.text)
                    this.showFeature()
                } catch(e) {
                    // something failed

                    // if you want to be specific and only catch the error which means
                    // the base 64 was invalid, then check for 'e.code === 5'.
                    // (because 'DOMException.INVALID_CHARACTER_ERR === 5')
                }
            },
            featuresDecodeMorse() {
                this.featureText = MorseCode.decode(this.message.text)
                this.showFeature()
            },
            showFeature(){
                this.featureTextDisplay = true
                this.displayText = this.featureText
            },
            hideFeature(){
                this.featureTextDisplay = false
                this.displayText = this.message.text
            },
            transcribTimestamp(unix_timestamp){
                // Create a new JavaScript Date object based on the timestamp
                // multiplied by 1000 so that the argument is in milliseconds, not seconds
                var date = new Date(unix_timestamp);

                // Hours part from the timestamp
                var hours = date.getHours();

                // Minutes part from the timestamp
                var minutes = "0" + date.getMinutes();

                // Seconds part from the timestamp
                var seconds = "0" + date.getSeconds();

                // Will display time in 10:30:23 format
                var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                
                console.log(formattedTime);
                console.log('Today is ' + date.getDate())
                
                return formattedTime
            }
        },
        watch: {
            message: {
                handler (val, oldVal) {
                    this.displayText = this.message.text
                }
            }
        }
    }
</script>