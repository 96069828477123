<template>
    <div class="room-lobby">
        <div class="room-header">
            <div :style="{'width': m_timer_percentage + '%'}" class="room-header-timer-bar">
            </div>
            <div class="room-header-statistics">
                <h2 class='room-title'>{{this.roomStore.room.title}}</h2>
                <div><span v-if="this.timer>=0">{{ this.timer }}</span></div>
                <div class="players-num">
                    <div v-if="this.roomStore.room.status!==2">
                        {{ this.playersRoomStore.players.length }}
                        {{ this.roomStore.room.min_players }} / {{this.roomStore.room.max_players}}
                    </div>
                    <div v-else class="room-stat">
                        <div>Mafs: {{ this.roomStore.maf_cur }} / {{this.roomStore.maf_max}}</div>
                        <div>Civs: {{ this.roomStore.civ_cur }} / {{this.roomStore.civ_max}}</div>
                    </div>
                </div>
            </div>
            <span class="room-header-role" v-if="this.roomStore.room.status===2 && this.playersRoomStore.players.length>0">
                
                <h1>{{ this.playersRoomStore.role }}</h1>
                <h1>{{ this.playersRoomStore.whoOnMe }}</h1>
                <video width="80" height="80" autoplay loop muted>
                    <source :src=getRoleIcon(this.transcribRole(this.playersRoomStore.role)) type="video/webm">
                    Your browser does not support the video tag.
                </video>
            </span>
        </div>
        <div class="room-lobby-container">
            <div ref="messages_ref" class="room-lobby-left">
                <Messages @add-text-event="addText" :messages="messages"></Messages>
            </div>
            <div class="room-lobby-right">
                <PlayersListInRoom v-if="this.roomStore.room.status<2"></PlayersListInRoom>
                <div v-else>
                    <PlayersListInGameplay v-if="this.playersRoomStore.players.length>0"></PlayersListInGameplay>
                </div>
            </div>
        </div>
        <div class="room-lobby-downpart">
            <SendRoomMessage ref="send_message_ref"></SendRoomMessage>
            <div class="room-lobby-roles">
                <img v-if="this.roomStore.room.is_bar===true" width="42px" height="42px" src="/barman.png">
                <img v-if="this.roomStore.room.is_lv===true" width="42px" height="42px" src="/lover.png">
                <img v-if="this.roomStore.room.is_inf===true" width="42px" height="42px" src="/informer.png">
                <img v-if="this.roomStore.room.is_jur===true" width="42px" height="42px" src="/journalist.png">
                <img v-if="this.roomStore.room.is_doc===true" width="42px" height="42px" src="/doctor.png">
                <img v-if="this.roomStore.room.is_terr===true" width="42px" height="42px" src="/terrorist.png">
                <img v-if="this.roomStore.room.is_spy===true" width="42px" height="42px" src="">
                <img v-if="this.roomStore.room.is_body===true" width="42px" height="42px" src="">
            </div>
        </div>
    </div>
    
</template>

<script>
    import {ref} from 'vue'
    import Messages from './Messages.vue'
    import PlayersListInRoom from './PlayersListInRoom.vue'
    import PlayersListInGameplay from './PlayersListInGameplay.vue'
    import SendRoomMessage from './SendRoomMessage.vue'

    import { useRoomStore } from '@/stores/RoomStore'
    import { usePlayersRoomStore } from '@/stores/PlayersRoomStore'

    const send_message_ref = ref(null)

    const ROLE_CIV = 		1
    const ROLE_DOC = 		2
    const ROLE_COMISSAR = 		3
    const ROLE_MAFIA = 		4
    const ROLE_LOVER = 		5
    const ROLE_TERRORIST = 		6
    const ROLE_JOURNALIST = 		7
    const ROLE_BODYGUARD = 		8
    const ROLE_BARMAN = 		9
    const ROLE_SPY = 		10
    const ROLE_INFORMER = 		11

    export default {
        data(){
            return {
                m_maxTimer: -1,
                m_lastTimer: -1,
                m_timer_percentage: 100,
            }
        },
        setup(){
            const roomStore = useRoomStore()
            const playersRoomStore = usePlayersRoomStore()
            return {roomStore, playersRoomStore}
        },
        components: {
            Messages,
            PlayersListInRoom,
            PlayersListInGameplay,
            SendRoomMessage,
        },
        props: ['messages', 'timer'],
        methods: {
            addText(text){
                this.$refs.send_message_ref.addText(text)
            },
            transcribRole(role){
                switch(role){
                    case ROLE_CIV:
                        return 'Civilian'
                    case ROLE_DOC:
                        return 'Doctor'
                    case ROLE_COMISSAR:
                        return 'Sheriff'
                    case ROLE_MAFIA:
                        return 'Mafia'
                    case ROLE_LOVER:
                        return 'Lover'
                    case ROLE_TERRORIST:
                        return 'Terrorist'
                    case ROLE_JOURNALIST:
                        return 'Journalist'
                    case ROLE_BODYGUARD:
                        return 'Bodyguard'
                    case ROLE_BARMAN:
                        return 'Barman'
                    case ROLE_SPY:
                        return 'Spy'
                    case ROLE_INFORMER:
                        return 'Informer'
                    default:
                        return 'unknown role'
                }
            },
            getRoleIcon(role){
                switch(role){
                    case ROLE_CIV:
                        return 'Civilian'
                    case ROLE_DOC:
                        return '/anims/doctor.webp'
                    case ROLE_COMISSAR:
                        return '/sheriff.png'
                    case ROLE_MAFIA:
                        return 'Mafia'
                    case ROLE_LOVER:
                        return '/anims/lover.webp'
                    case ROLE_TERRORIST:
                        return '/anims/terrorist.webp'
                    case ROLE_JOURNALIST:
                        return '/journalist.png'
                    case ROLE_BODYGUARD:
                        return 'Bodyguard'
                    case ROLE_BARMAN:
                        return '/barman.png'
                    case ROLE_SPY:
                        return 'Spy'
                    case ROLE_INFORMER:
                        return '/anims/informer.webp'
                    default:
                        return 'unknown role'
                }
            }
        },
        watch: {
            timer: {
                immediate: true,
                handler (val, oldVal) {
                    if(oldVal === undefined){
                        this.m_maxTimer = val
                    }
                    if (val > oldVal) {
                        this.m_maxTimer = this.timer
                    }
                    this.m_lastTimer = this.timer
                    this.m_timer_percentage = this.m_lastTimer/this.m_maxTimer * 100
                }
            },
            messages: {
                deep: true,
                handler (val, oldVal) {
                    if (this.$refs.messages_ref.scrollTop + this.$refs.messages_ref.clientHeight === this.$refs.messages_ref.scrollHeight)
                    {
                        this.$nextTick(() => {
                            this.$refs.messages_ref.scrollTop = this.$refs.messages_ref.scrollHeight;
                        });
                    }
                }
            }
        }
    }
</script>

<style>
.room-lobby {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: var(--chat-bg);
    color: black;
    border-radius: 5px;
    padding: 15px 10px;
    flex: 1;
}
.room-lobby-container {
    display: flex;
    justify-content: space-between;
    overflow: auto;
    flex: 1;
}
.room-lobby-roles {
    display: flex;
    justify-content: end;
}
.room-lobby-left {
    overflow: auto;
    flex: 1;
}
.room-lobby-right {
    overflow: auto;
}
.room-header {
    box-shadow: 0 19px 14px -14px #fff;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.room-header-statistics {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
}
.room-title {
    margin: 0 15px 10px 15px;
    font-weight: normal;
    font-size: 18px;
}
.room-lobby-downpart {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.players-num {
    display: flex;
    width: 100px;
    justify-content: space-between;
}
.room-header-timer-bar {
    background-color: #409af78a;
    height: 100%;
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 0 10px #a08de6;
    transition: width 1s;
    transition-timing-function: linear;
}
.room-header-role {
    display: flex;
    flex-direction: column;
}
.room-stat {
    display: flex;
    flex-direction: column;
}
</style>