import {defineStore} from 'pinia'

export const useRoomStore = defineStore('roomStore', {
    state: () => {
        return { 
            room: null,

            civ_cur: 0,
            civ_max: 0,
            maf_cur: 0,
            maf_max: 0,
        }
    },
})