<template>
    <div class="sendmessage-features">

        <Dropdown :options="
            [
                {'name':'-','value':'normal'},
                {'name':'base64','value':'base64'},
                {'name':'pseudobar','value':'pseudobar'},
                {'name':'kana','value':'toKana'},
                {'name':'morse','value':'morse'},
                {'name':'morse (cyr)','value':'morseCyr'}
            ]" 
        
        
            v-model=this.feature></Dropdown>
    </div>
</template>

<script>
    import * as wanakana from 'wanakana';
    import MorseCode from '@/morsecode'
    import { Base64 } from 'js-base64';
    import Dropdown from './Dropdown.vue';

    const pseudobarSymbols = '#!%*&^?<~@>'
    export default {
        data() {
            return {
                feature: "",
            }
        },
        props: ['message'],
        methods: {
            applyFeature(text){
                let outText
                switch(this.feature.value){
                    case 'base64':
                        try {
                            outText = Base64.encode(text)
                            //outText = Buffer.from(text, 'base64').toString("utf8")
                        } catch(e) {
                            // something failed

                            // if you want to be specific and only catch the error which means
                            // the base 64 was invalid, then check for 'e.code === 5'.
                            // (because 'DOMException.INVALID_CHARACTER_ERR === 5')
                        }
                        break
                    case 'pseudobar':
                        outText = ''
                        for (let i = 0; i < text.length; i++) {
                            outText += pseudobarSymbols.charAt(Math.floor(Math.random() * pseudobarSymbols.length));
                        }
                        break
                    case 'toKana':
                        outText = wanakana.toKatakana(text)
                        {
                            const convertFullToHalf = (str) => str.replace(/[！-～]/g, r => String.fromCharCode(r.charCodeAt(0) - 0xFEE0));
                            outText = convertFullToHalf(outText)
                        }
                        break
                    case 'morse':
                        outText = MorseCode.encode(text)
                        break
                    case 'morseCyr':
                        outText = MorseCode.encodeCyr(text)
                        break
                    default:
                        outText = text
                }
                return outText
            },
            addText(text){
                this.$parent.$emit('add-text-event', '[' + text + '] ');
            },
        },
        components: {
            Dropdown
        },
    }
</script>

<style>
.sendmessage-features {
    margin-right: 10px;
}
</style>