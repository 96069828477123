<template>
    <div class="public-chat">
        <div class="public-chat-container">
            <div ref="messages_ref" class="public-chat-left">
                <Messages @add-text-event="addText"></Messages>
            </div>
            <div class="public-chat-right">
                <PlayersList :players="playersStore.players"></PlayersList>
            </div>
        </div>
        <SendChatMessage ref="send_message_ref"></SendChatMessage>
    </div>
</template>

<script>
    import {ref} from 'vue'
    import Messages from './Messages.vue'
    import PlayersList from './PlayersList.vue';
    import SendChatMessage from './SendChatMessage.vue'

    import { usePlayersStore } from '@/stores/PlayersStore';
    import { useMessagesStore } from '@/stores/MessagesStore';

    export default {
        components: {
            Messages,
            PlayersList,
            SendChatMessage
        },
        setup(){
            const playersStore = usePlayersStore()
            const messagesStore = useMessagesStore()
            return {playersStore, messagesStore}
        },
        methods: {
            addText(text){
                this.$refs.send_message_ref.addText(text)
            },
        },
        watch: {
            messagesStore: {
                deep: true,
                handler (val, oldVal) {
                    if (this.$refs.messages_ref.scrollTop + this.$refs.messages_ref.clientHeight === this.$refs.messages_ref.scrollHeight)
                    {
                        this.$nextTick(() => {
                            this.$refs.messages_ref.scrollTop = this.$refs.messages_ref.scrollHeight;
                        });
                    }
                }
            }
        }
    }
</script>

<style>
.public-chat {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: var(--chat-bg);
    color: black;
    border-radius: 5px;
    padding: 15px 10px;
    flex: 1;
}
.public-chat-container {
    display: flex;
    justify-content: space-between;
    overflow: auto;
    flex: 1;
}
.public-chat-left {
    overflow: auto;
    flex: 1;
}
.public-chat-right {
    overflow: auto;
}
</style>