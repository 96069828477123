<template>
    <div class="createroom-container">
        <form class="createroom-form" @submit.prevent="idk">
        <label class="createroom-label">
            Title
            <input class="createroom-input" type="text" v-model="title" />
        </label>
        <label class="createroom-label">
            Min players
            <input class="createroom-input" type="text" v-model="minPlayers" />
        </label>
        <label class="createroom-label">
            Max players
            <input class="createroom-input" type="text" v-model="maxPlayers" />
        </label>
        <label class="createroom-label">
            Min level
            <input class="createroom-input" type="text" v-model="minLevel" />
        </label>
        <label class="createroom-label">
            Password
            <input class="createroom-input" type="password" v-model="password" />
        </label>

        <span>
            <label for="createroom-doc">Doctor</label>
            <input id="createroom-doc" type="checkbox" v-model="isDoc">
        </span>
        <span>
            <label for="createroom-lover">Lover</label>
            <input id="createroom-lover" type="checkbox" v-model="isLover">
        </span>
        <span>
            <label for="createroom-jour">Journalist</label>
            <input id="createroom-jour" type="checkbox" v-model="isJour">
        </span>
        <span>
            <label for="createroom-spy">Spy</label>
            <input id="createroom-spy" type="checkbox" v-model="isSpy">
        </span>
        <span>
            <label for="createroom-bar">Barmen</label>
            <input id="createroom-bar" type="checkbox" v-model="isBar">
        </span>
        <span>
            <label for="createroom-bodyguard">Bodyguard</label>
            <input id="createroom-bodyguard" type="checkbox" v-model="isBodyguard">
        </span>
        <span>
            <label for="createroom-terr">Terrorist</label>
            <input id="createroom-terr" type="checkbox" v-model="isTerr">
        </span>
        <span>
            <label for="createroom-inf">Informer</label>
            <input id="createroom-inf" type="checkbox" v-model="isInf">
        </span>
        <button class="createroom-submit-button" @click="createRoom">Create</button>
        </form>
    </div>
</template>

<script>
    import { useUserStore } from '../stores/UserStore';

    export default {
        setup(){
            const userStore = useUserStore()
            return {userStore}
        },
        data() {
            return {
                title: "",
                minPlayers: 7,
                maxPlayers: 12,
                minLevel: 1,
                password: "",
                isDoc: true,
                isLover: true,
                isJour: true,
                isSpy: true,
                isBar: true,
                isBodyguard: true,
                isTerr: true,
                isInf: true,
            }
        },
        methods: {
            createRoom(){
                const ROLE_DOC = 		"2"
                const ROLE_LOVER = 		"5"
                const ROLE_TERRORIST = 		"6"
                const ROLE_JOURNALIST = 		"7"
                const ROLE_BODYGUARD = 		"8"
                const ROLE_BARMAN = 		"9"
                const ROLE_SPY = 		"10"
                const ROLE_INFORMER = 		"11"

                let roles_arr = []
                if (this.isDoc){
                    roles_arr.push(ROLE_DOC)
                }
                if (this.isLover){
                    roles_arr.push(ROLE_LOVER)
                }
                if (this.isJour){
                    roles_arr.push(ROLE_JOURNALIST)
                }
                if (this.isSpy){
                    roles_arr.push(ROLE_SPY)
                }
                if (this.isBar){
                    roles_arr.push(ROLE_BARMAN)
                }
                if (this.isBodyguard){
                    roles_arr.push(ROLE_BODYGUARD)
                }
                if (this.isTerr){
                    roles_arr.push(ROLE_TERRORIST)
                }
                if (this.isInf){
                    roles_arr.push(ROLE_INFORMER)
                }
                this.$parent.$emit('send-message-event', JSON.stringify({"rr":{"sr":roles_arr,"mxp":this.maxPlayers,"mnl":this.minLevel,"mnp":this.minPlayers,"pw":this.password,"tt":this.title},"t":this.userStore.user.token,"ty":"rc","uo":this.userStore.user.id}))
            }
        }
    }
</script>

<style>
.createroom-form {
    display: flex;
    flex-direction: column;
}
</style>