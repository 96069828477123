<template>
    <form class="login-form" @submit.prevent="loginWithPassword">
      <label class="login-label">
        Email or username
        <input class="login-input" type="text" v-model="login" />
      </label>
      <label class="login-label">
        Password
        <input class="login-input" type="password" v-model="password" />
      </label>
      <span>
        <label for="login-remember-me">Remember me</label>
        <input id="login-remember-me" type="checkbox" v-model="rememeberMe">
      </span>
      <button class="login-button" @click="submit">Log in</button>
    </form>
</template>

<script>
    import CryptoJS from 'crypto-js';
    
    export default {
        data() {
            return {
                login: "",
                password: "",
                alert: "",
                rememeberMe: false,
            }
        },
        methods: {
            submit(){
                const hashedPassword = this.md5hash(this.password).toString()
                console.log(this.password)
                console.log(hashedPassword)
                const randomDeviceID = this.genRanHex(16)
                console.log(randomDeviceID)
                this.$parent.$emit('send-message-event', JSON.stringify({"ty":"sin","e":this.login,"pw":hashedPassword,"d":randomDeviceID}))
            },
            md5hash(data){
                let md5res = data;
                for(let i = 0; i < 5; i++){
                    md5res = CryptoJS.MD5(md5res + 'azxsw')
                }
                return md5res;
            },
            genRanHex(size) { return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('') }
        }
    }
</script>

<style>
.login-form {
    display: flex;
    flex-direction: column;
    margin: 40px auto;
    font-size: 24px;
}
.login-button {
    margin-top: 10px;
    padding: 8px 25px;
    align-self: center;
    font-size: 18px;
}
.login-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0;
}
.login-input {
    margin-left: 15px;
}
</style>