<template>
    <div v-if="this.userStore.user != null">
        <p>Hello <i>{{ this.userStore.user.name }}</i>, from home page</p>
        <div class="userinfo-container">
            <div class="userinfo-header">
                <div class="userinfo-left">
                    <img @click="imageViewToggle" :class="fullImageToggle == false ? 'userinfo-pfp' : 'userinfo-pfp-full'" width="256px" height="256px" v-bind:src="'http://37.143.8.68/mafia/profile_photo/'+userStore.user.id+'.jpg'">
                
                
                    <label>Select Profile Picture
                        <input type="file" name="myImage" accept="image/jpeg" @change="onImageSelected"/>
                    </label>
                </div>
                <div class="userinfo-right">
                    <div class="userinfo-right-left">
                        <span :class="userStore.user.sex == '1' ? 'userinfo-username-girl' : 'userinfo-username-boy'" class="userinfo-username">{{ userStore.user.name }}</span>
                    </div>
                </div>
            </div>
            <div class="userinfo-statistics">
                <div class="section-title">Played as</div>
                <section class="played-as-section">
                    <ul>
                        <li class="userinfo-played-total">Total: {{userStore.user.played_total}}</li>
                        <li>Mafia: {{userStore.user.as_maf}}</li>
                        <li>Sheriff: {{userStore.user.as_sher}}</li>
                        <li>Doctor: {{userStore.user.as_doc}}</li>
                        <li>Lover: {{userStore.user.as_lov}}</li>
                        <li>Terrorist: {{userStore.user.as_ter}}</li>
                        <li>Journalist: {{userStore.user.as_jour}}</li>
                        <li>Bodyguard: {{userStore.user.as_bd}}</li>
                        <li>Barmen: {{userStore.user.as_bar}}</li>
                        <li>Spy: {{userStore.user.as_spy}}</li>
                        <li>Civilians: {{userStore.user.as_civ}}</li>
                        <li>Informer: {{userStore.user.as_inf}}</li>
                    </ul>
                </section>
                <div class="section-title">Wins as</div>
                <section class="played-as-section">
                    <ul>
                        <li>Civilians: {{userStore.user.wins_civ}}</li>
                        <li>Mafia: {{userStore.user.wins_maf}}</li>
                    </ul>
                </section>
            </div>
        </div>

        <p v-if="userStore.user.new_messages>0">{{ userStore.user.new_messages }} new message(s)</p>
        <p v-if="userStore.user.new_friends>0">{{ userStore.user.new_friends }} new friend(s)</p>
    </div>
</template>

<script>
import { useUserStore } from '@/stores/UserStore';
    export default {
        setup(){
            const userStore = useUserStore()
            return {userStore}
        },
        data(){
            return {
                fullImageToggle: false,
            }
        },
        methods: {
            imageViewToggle(){
                this.fullImageToggle = !this.fullImageToggle
            },
            onImageSelected(event){
                const files = event.target.files
                let filename = files[0].name
                const fileReader = new FileReader()
                fileReader.addEventListener('load', () => {
                    console.log(fileReader.result)
                    this.$emit('send-message-event', JSON.stringify({"ty":"upp","uo":this.userStore.user.id, 't':this.userStore.user.token, "f":fileReader.result.replace(/^data:image\/[a-z]+;base64,/, "")}));
                })
                fileReader.readAsDataURL(files[0])
            }
        }
    }
</script>