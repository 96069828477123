<template>
    <div class="players-container">
        <ul class="players-gameplay-list">
            <li class="player-element" v-for="player in this.playersRoomStore.players" :key="player.id">
                <div v-if="player.data!=null" class="player-gameplay-box" @click="applyRole(player.id)">
                    <span class="player-name" :class="player.sex == '1' ? 'player-girl' : 'player-boy'">{{ player.name }}</span>
                    <img :src=this.getRoleIcon(player.data.role) width="90px" alt="">
                    role: {{this.transcribRole(player.data.role)}}
                    <br>
                    <span v-if="player.data.votes>0" class="player-element-votes">{{ player.data.votes }}</span>
                    <span v-if="player.data.alive===false">dead</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import { usePlayersRoomStore } from '@/stores/PlayersRoomStore';
    import { useRoomStore } from '@/stores/RoomStore';

    const ROLE_CIV = 		1
    const ROLE_DOC = 		2
    const ROLE_COMISSAR = 		3
    const ROLE_MAFIA = 		4
    const ROLE_LOVER = 		5
    const ROLE_TERRORIST = 		6
    const ROLE_JOURNALIST = 		7
    const ROLE_BODYGUARD = 		8
    const ROLE_BARMAN = 		9
    const ROLE_SPY = 		10
    const ROLE_INFORMER = 		11

    export default {
        setup(){
            const roomStore = useRoomStore()
            const playersRoomStore = usePlayersRoomStore()
            return {roomStore, playersRoomStore}
        },
        methods: {
            applyRole(playerID){
                //{"ty":"ra", "uo": get_parent().m_player.id, "ro":user.current_room.id}
                this.$parent.$emit('send-message-event', JSON.stringify({"ty":"ra","uo":playerID, "ro":this.roomStore.room.id}))
            },
            transcribRole(role){
                console.log(role, this.playersRoomStore.players)
                switch(role){
                    case ROLE_CIV:
                        return 'Civilian'
                    case ROLE_DOC:
                        return 'Doctor'
                    case ROLE_COMISSAR:
                        return 'Sheriff'
                    case ROLE_MAFIA:
                        return 'Mafia'
                    case ROLE_LOVER:
                        return 'Lover'
                    case ROLE_TERRORIST:
                        return 'Terrorist'
                    case ROLE_JOURNALIST:
                        return 'Journalist'
                    case ROLE_BODYGUARD:
                        return 'Bodyguard'
                    case ROLE_BARMAN:
                        return 'Barmen'
                    case ROLE_SPY:
                        return 'Spy'
                    case ROLE_INFORMER:
                        return 'Informer'
                    default:
                        return 'unknown role'
                }
            },
            getRoleIcon(role){
                switch(role){
                    case ROLE_CIV:
                        return 'Civilian'
                    case ROLE_DOC:
                        return '/doctor.png'
                    case ROLE_COMISSAR:
                        return '/sheriff.png'
                    case ROLE_MAFIA:
                        return 'Mafia'
                    case ROLE_LOVER:
                        return '/lover.png'
                    case ROLE_TERRORIST:
                        return '/terrorist.png'
                    case ROLE_JOURNALIST:
                        return '/journalist.png'
                    case ROLE_BODYGUARD:
                        return 'Bodyguard'
                    case ROLE_BARMAN:
                        return '/barman.png'
                    case ROLE_SPY:
                        return 'Spy'
                    case ROLE_INFORMER:
                        return '/informer.png'
                    default:
                        return 'unknown role'
                }
            }
            
        }
    }
</script>

<style>
.player-girl {
    background-color: #ff6cda;
}
.player-boy {
    background-color: rgb(62, 162, 255);
}
.players-container {
    min-width: 250px;
}
.player-name {
    padding: 3px 10px;
    border-radius: 7px;
    font-weight: bold;
    color: var(--playername-color);
}
.player-element {
    margin: 5px 5px;
}
.player-gameplay-box {
    width: 80px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    background-color: #72a7fb8a;
    color: black;
    position: relative;
    border-radius: 8px;
    border: #ffffff87 3px solid;
    transition: box-shadow 0.2s ease-out;
}
.player-gameplay-box:hover {
    box-shadow: 0 0 10px 6px #3a71b9a8;
    background-color: #2f81ec8a;
}
.player-element-votes {
    background-color: red;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 2px 5px;
}
.players-gameplay-list {
    width: 270px;
    display: flex;
    flex-wrap: wrap;
}
</style>