<template>
    <div class="messages-container">
        <ul>
            <li class="message-element" v-for="message in messagesStore.messages" :key="message.id">
                <PrivateMessage :message=message></PrivateMessage>
            </li>
        </ul>
    </div>
</template>

<script>
    import { useCurrentFriendChatStore } from '@/stores/CurrentFriendChat';
    import { useUserStore } from '@/stores/UserStore';
    import { useMessagesStore } from '@/stores/MessagesStore';
    import PrivateMessage from './PrivateMessage.vue'
    export default {
        setup(){
            const currentFriendChatStore = useCurrentFriendChatStore()
            const userStore = useUserStore()
            const messagesStore = useMessagesStore();
            return {currentFriendChatStore, userStore, messagesStore}
        },
        methods: {
            addText(text){
                this.$emit('add-text-event', '[' + text + '] ');
            },
            convertTime(unix_timestamp){
                var date = new Date(unix_timestamp);

                // Hours part from the timestamp
                var hours = date.getHours();

                // Minutes part from the timestamp
                var minutes = "0" + date.getMinutes();

                // Seconds part from the timestamp
                var seconds = "0" + date.getSeconds();

                // Will display time in 10:30:23 format
                var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                return formattedTime
            },
            computeUsername(id){
                if(id === this.userStore.user.id){
                    return this.userStore.user.name
                } else {
                    console.log("friend shit", this.currentFriendChatStore.friend)
                    return this.currentFriendChatStore.friend.username
                }
            }
        },
        components: {
            PrivateMessage
        }
    }
</script>