<template>
    <div v-if='$route.params.id != null' class="private-chat">
        <div class="private-chat-container">
            <div ref="messages_ref" class="private-chat-left">
                <PrivateMessages @add-text-event="addText" :messages="messagesStore.messages"></PrivateMessages>
            </div>
        </div>
        <SendPrivateMessage :friend_id=$route.params.id ref="send_message_ref"></SendPrivateMessage>
    </div>
</template>

<script>
    import {ref} from 'vue'
    import * as wanakana from 'wanakana';
    import { useMessagesStore } from '@/stores/MessagesStore';
    import PrivateMessages from './PrivateMessages.vue'
    import SendPrivateMessage from './SendPrivateMessage.vue'

    export default {
        components: {
            PrivateMessages,
            SendPrivateMessage
        },
        setup(){
            const messagesStore = useMessagesStore()
            return {messagesStore}
        },
        methods: {
            addText(text){
                this.$refs.send_message_ref.addText(text)
                this.$refs.messages_ref.scrollIntoView({ behavior: 'smooth' });
            }
        },
        watch: {
            messagesStore: {
                deep: true,
                handler (val, oldVal) {
                    if (this.$refs.messages_ref.scrollTop + this.$refs.messages_ref.clientHeight === this.$refs.messages_ref.scrollHeight)
                    {
                        this.$nextTick(() => {
                            this.$refs.messages_ref.scrollTop = this.$refs.messages_ref.scrollHeight;
                        });
                    }
                }
            }
        }
    }
</script>

<style>
.private-chat {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: var(--chat-bg);
    color: black;
    border-radius: 5px;
    padding: 15px 10px;
    flex: 1;
}
.private-chat-container {
    display: flex;
    justify-content: space-between;
    overflow: auto;
    flex: 1;
}
.private-chat-left {
    overflow: auto;
    flex: 1;
}
.private-chat-right {
    overflow: auto;
}
</style>